import { Customer, Firm } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import useFetchCustomer from '~/src/hooks/customer/useFetchCustomer'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { updateCustomer } from '~/src/services/CustomerService'
import { IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  update(customer: Customer): Promise<void>,
  error: string | null,
  loading: boolean,
}

const useUpdateCustomer = (): ReturnsType => {
  const { t } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch: fetchCustomer } = useFetchCustomer()
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const setCustomerState = useSetEoState(CUSTOMER_STATE)
  const isUsingNewBrandAuthSystem = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)
  const [ error, setError ] = useState<string | null>(null)
  const [ loading, setLoading ] = useState<boolean>(false)

  const update = async (customer: Customer): Promise<void> => {
    setError(null)
    setLoading(true)

    try {
      await updateCustomer(customer, isUsingNewBrandAuthSystem, brand, firm)

      enqueueSnackbar(t('customer.updated'), { variant: 'success' })
      const updatedCustomer: Customer | undefined = await fetchCustomer()

      updatedCustomer && setCustomerState((state: Customer | null) => ({
        ...state,
        ...updatedCustomer
      }))
    } catch {
      setError(t('errors.default_error'))
    } finally {
      setLoading(false)
    }
  }

  return { update, error, loading }
}

export default useUpdateCustomer
